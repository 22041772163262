import { Helmet } from "react-helmet-async";
import filter from "lodash/filter";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
// @mui
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popover from "@mui/material/Popover";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
import { QrReader } from "react-qr-reader";
// sections
import { UserListHead, UserListToolbar } from "../sections/@dashboard/user";
// redux
import { useSelector, useDispatch } from "react-redux";
import { fetchUsers } from "../redux/usersTableSlice";

// ----------------------------------------------------------------------

const sendSMSUrl = "/api/send-user-profile-link";
const sendDesignationURL = "/api/designating-shop-on-behalf-and-reverse";

const housingList = [
	"CAHA",
	"NVHA",
	"GHA",
	"CRHA",
	"THENUE",
	"HOME GROUP",
	"TEMP HOUSING",
	"UNKNOWN-OTHER",
];

const TABLE_HEAD = [
	{ field: "name", label: "Name | Email", minWidth: 170, align: "left" },
	{ field: "status", label: "Status Date-join", minWidth: 170, align: "right" },
	{
		field: "housing_association_id",
		label: "Housing association",
		width: 150,
		editable: true,
	},
	{ field: "address", label: "Address", width: 150, editable: true },
	{ field: "comments", label: "Comments", width: 200, editable: true },

	{
		field: "mobile_number",
		label: "Mobile number",
		minWidth: 170,
		align: "left",
	},

	{
		field: "qrcode",
		label: "Pantry profile",
		width: 150,
	},
	{
		field: "",
	},
	// {
	// 	field: "Delete",
	// 	headerName: "Delete",
	// 	width: 75,
	// 	renderCell: (params) => (
	// 		<>
	// 			<Button onClick={() => handleDeleteEmployee(params.row.user_id)}>
	// 				<DeleteForeverRoundedIcon />
	// 			</Button>
	// 		</>
	// 	),
	// 	editable: false,
	// },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	if (query) {
		return filter(
			array,
			(_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
		);
	}
	return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
	const [open, setOpen] = useState(null);
	const [data, setData] = useState("Scanning");
	const [selectedData, setSelectedData] = useState("");
	const [buttonActive, setButtonActive] = useState(false);
	const [page, setPage] = useState(0);
	const [order, setOrder] = useState("asc");
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState("name");
	const [filterName, setFilterName] = useState("");
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [loading, setLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState(null);
	const [alertSeverity, setAlertSeverity] = useState("success");
	const [openSnack, setOpenSnack] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const theme = useTheme(); // Get the theme
  	const matches = useMediaQuery(theme.breakpoints.down("sm")); // Check if screen size is small


	const handleDialogClickOpen = () => {
		setOpenDialog(true);
	};

	const handleDialogClose = () => {
		setOpenDialog(false);
	};

	const handleCloseSnack = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenSnack(false);
	};

	const dispatch = useDispatch();
	const message
    = "Hi there! your Castlemilk Pantry profile is here, tap this link https://castlemilkpantry.co.uk/pantry-profile/";

	useEffect(() => {
		dispatch(fetchUsers());
	}, [dispatch]);

	const userList = useSelector((state) => state.data.userTable.users);

	const sendSMS = async (data) => {
		const sendMSMData = {
			ids: data,
			message,
		};
		setLoading(true);
		try {
			const response = await fetch(sendSMSUrl, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(sendMSMData),
			});
			if (response.ok) {
				setAlertSeverity("success");
				setAlertMessage("SMS sent successfully");
				setOpenSnack(true);
			} else {
				setAlertSeverity("error");
				setAlertMessage("Failed to send SMS");
				setOpenSnack(true);
			}
		} catch (error) {
			setAlertSeverity("error");
			setAlertMessage("Failed to send SMS");
			setOpenSnack(true);
		} finally {
			setLoading(false);
		}
	};

	const sendDesignation = async (data) => {
		const userData = selectedData[0];
		const sendDesignationData = {
			user_id: userData.user_id,
			is_shop_on_behalf_active: data.is_shop_on_behalf_active,
			who_you_designate_shop_on_behalf: data.who_you_designate_shop_on_behalf,
		};
		setLoading(true);
		try {
			const response = await fetch(sendDesignationURL, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(sendDesignationData),
			});
			// if (response.ok) {
			// 	setAlertSeverity("success");
			// 	setAlertMessage("SMS sent successfully");
			// 	setOpenSnack(true);
			if (response.ok) {
				setAlertSeverity("success");
				setAlertMessage("Designation updated successfully");
				setSelectedData("");
				setOpenDialog(false);
			} else {
				setAlertSeverity("error");
				setAlertMessage("Failed to update designation");
				setOpenSnack(true);
			}
		} catch (error) {
			setAlertSeverity("error");
			setAlertMessage("Failed to make the designation");
			setOpenSnack(true);
		} finally {
			setLoading(false);
		}
	};

	// const handleDelete = (userId) => {
	// 	dispatch(deleteUser(userId)); // Implement the deleteUser logic in your slice
	// };

	const handleOpenMenu = (event) => {
		setOpen(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelecteds = userList.map((n) => n.name);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const handleFilterByName = (event) => {
		setPage(0);
		setFilterName(event.target.value);
	};

	const emptyRows
    = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

	const filteredUsers = applySortFilter(
		userList,
		getComparator(order, orderBy),
		filterName
	);

	const isNotFound = !filteredUsers.length && !!filterName;

	const handleSendSMS = () => {
		if (selected[0]) {
			const mobileNumbers = selected.map((name) => {
				const user = userList.find((user) => user.name === name);
				return user
					? { mobile_number: user.mobile_number, qrcode: user.qrcode }
					: null;
			});
			const validMobileNumbers = mobileNumbers.filter(
				(number) => number != null
			);
			sendSMS(validMobileNumbers);
		} else {
			setAlertSeverity("error");
			setAlertMessage("No member selected");
			setOpenSnack(true);
		}
	};

	const handleSendSMSNonMemberDesignation = () => {
		if (selected[0]) {
			const mobileNumbers = selected.map((name) => {
				const user = userList.find((user) => user.name === name);
				return user
					? { mobile_number: user.mobile_number, qrcode: user.qrcode }
					: null;
			});
			const validMobileNumbers = mobileNumbers.filter(
				(number) => number != null
			);
			sendSMS(validMobileNumbers);
		} else {
			setAlertSeverity("error");
			setAlertMessage("No member selected");
			setOpenSnack(true);
		}
	};


	const applyDesignation = async () => {
		await sendDesignation({ is_shop_on_behalf_active: true, who_you_designate_shop_on_behalf: data });
	};

	const removeDesignation = async () => {
		if (selected[0]) {
			const whoShopInYourBehalfQRCode = selected.map((name) => {
				const user = userList.find((user) => user.name === name);
				return user;
			});
			const whoYouDesignate = whoShopInYourBehalfQRCode;
			await sendDesignation({
				is_shop_on_behalf_active: false,
				who_you_designate_shop_on_behalf: whoYouDesignate[0].who_you_designate_shop_on_behalf,
			});
		}
	};

	const showAlert = (severity, message) => {
		setAlertSeverity(severity);
		setAlertMessage(message);
		setOpenSnack(true);
	};

	const handleDesignation = () => {
		if (selected[0]) {
			setOpenDialog(true);
			const userIdGathering = selected.map((name) => {
				const user = userList.find((user) => user.name === name);
				return user
					? {
						user_id: user.user_id,
						is_shop_on_behalf_active: user.is_shop_on_behalf_active,
					}
					: null;
			});
			console.log(userIdGathering);
			setSelectedData(userIdGathering);
		} else {
			showAlert("error", "No member selected");
			setOpenSnack(true);
		}
	};

	const handlePrinting = () => {
		if (selected[0]) {
			const mobileNumbers = selected.map((name) => {
				const user = userList.find((user) => user.name === name);
				return user
					? { mobile_number: user.mobile_number, qrcode: user.qrcode }
					: null;
			});
			alert("Printing in progress", "member qrcode", mobileNumbers.qrcode);
			// const validMobileNumbers = mobileNumbers.filter(
			// 	(number) => number != null
			// );
			// sendSMS(validMobileNumbers);
		} else {
			setAlertSeverity("error");
			setAlertMessage("No member selected");
			setOpenSnack(true);
		}
	};

	return (
		<>
			<Helmet>
				<title> Membership </title>
			</Helmet>

			<Container>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="left"
					gap={"10px"}
					mb={5}
				>
					<Typography variant="h4" gutterBottom>
            User
					</Typography>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:plus-fill" />}
						sx={{
							display: "flex",
							justifyContent: "center",
							minWidth: "auto",
							paddingRight: !matches ? 1.6 : 0.6,
						  }}
					>
						{!matches && "New User"}
					</Button>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:edit-2-outline" />}
						sx={{
							display: "flex",
							justifyContent: "center",
							minWidth: "auto",
							paddingRight: !matches ? 1.6 : 0.6,
						  }}
					>
						{!matches && "Edit user"}
					</Button>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:message-square-outline" />}
						sx={{
							display: "flex",
							justifyContent: "center",
							minWidth: "auto",
							paddingRight: !matches ? 1.6 : 0.6,
						  }}
						onClick={handleSendSMS}
					>
						{!matches && "Send SMS"}
					</Button>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:shopping-cart-outline" />}
						sx={{
							display: "flex",
							justifyContent: "center",
							minWidth: "auto",
							paddingRight: !matches ? 1.6 : 0.6,
						  }}
						onClick={handleDesignation}
					>
						{!matches && "Shop on Behalf"}
					</Button>
					<Button
						variant="contained"
						startIcon={<Iconify icon="eva:printer-outline" />}
						sx={{
							display: "flex",
							justifyContent: "center",
							minWidth: "auto",
							paddingRight: !matches ? 1.6 : 0.6,
						  }}
						onClick={handlePrinting}
					>
						{!matches && "Print QRcode"}
					</Button>
				</Stack>

				<Card>
					<UserListToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>

					<TableContainer sx={{ minWidth: 800 }}>
						<Table>
							<UserListHead
								order={order}
								orderBy={orderBy}
								headLabel={TABLE_HEAD}
								rowCount={userList.length}
								numSelected={selected.length}
								onRequestSort={handleRequestSort}
								onSelectAllClick={handleSelectAllClick}
							/>
							<TableBody>
								{filteredUsers
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										const {
											user_id,
											status,
											name,
											address,
											postal_code,
											date_join,
											email,
											mobile_number,
											qrcode,
											housing_association_id,
											comments,
										} = row;
										const selectedUser = selected.indexOf(name) !== -1;

										return (
											<TableRow
												hover
												key={user_id}
												tabIndex={-1}
												role="checkbox"
												selected={selectedUser}
											>
												<TableCell padding="checkbox">
													<Checkbox
														checked={selectedUser}
														onChange={(event) => handleClick(event, name)}
													/>
												</TableCell>
												<TableCell>
													<Typography
														variant="body2"
														fontWeight="bold"
														color="text.primary"
														noWrap
													>
														{name}
													</Typography>
													<Typography
														variant="body2"
														color="text.secondary"
														noWrap
													>
														{email}
													</Typography>
												</TableCell>
												<TableCell align="left">
													<Label
														color={
															status === "Idle"
																? "info"
																: status === "Active"
																	? "success"
																	: status === "Active More Info Required"
																		? "warning"
																		: status === "Active Annual Payment Due More Info Required"
																			? "error"
																			: status === "Active Annual Payment Due"
																				? "error"
																				: "error"
														}
													>
														{sentenceCase(status)}
													</Label>
													<Typography
														variant="body2"
														color="text.secondary"
														noWrap
													>
														{date_join}
													</Typography>
												</TableCell>
												<TableCell align="left">
													<Typography variant="body2" color="text.primary">
														{isNaN(housing_association_id)
															? housing_association_id
															: housingList[
																parseInt(housing_association_id, 10) - 1
															]}
													</Typography>
												</TableCell>
												<TableCell align="left">
													<Typography
														variant="body2"
														fontWeight="bold"
														color="text.primary"
													>
														{postal_code}
													</Typography>
													<Typography
														variant="body2"
														color="text.secondary"
														noWrap
													>
														{address}
													</Typography>
												</TableCell>
												<TableCell align="left">
													<Typography
														variant="body2"
														color="text.secondary"
														noWrap
													>
														{comments}
													</Typography>
												</TableCell>
												<TableCell align="left">
													<Typography
														variant="body2"
														fontWeight="bold"
														color="text.primary"
														noWrap
													>
														{mobile_number}
													</Typography>
												</TableCell>
												<TableCell align="left">
													<Button
														variant="outlined"
														startIcon={<Iconify icon="ic:twotone-qrcode" />}
														component="a"
														href={`https://castlemilkpantry.co.uk/pantry-profile/${qrcode}`}
														target="_blank"
														rel="noopener noreferrer"
													></Button>
												</TableCell>
												<TableCell align="right">
													<IconButton
														size="large"
														color="inherit"
														onClick={handleOpenMenu}
													>
														<Iconify icon={"eva:more-vertical-fill"} />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>

							{isNotFound && (
								<TableBody>
									<TableRow>
										<TableCell align="center" colSpan={6} sx={{ py: 3 }}>
											<Paper
												sx={{
													textAlign: "center",
												}}
											>
												<Typography variant="h6" paragraph>
                          Not found
												</Typography>

												<Typography variant="body2">
                          No results found for &nbsp;
													<strong>&quot;{filterName}&quot;</strong>.
													<br /> Try checking for typos or using complete words.
												</Typography>
											</Paper>
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</TableContainer>

					<Box p={2}>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, 30]}
							component="div"
							count={userList.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Box>
				</Card>
			</Container>

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: "top", horizontal: "left" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 1,
						width: 140,
						"& .MuiMenuItem-root": {
							px: 1,
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<MenuItem>
					<Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Edit
				</MenuItem>

				<MenuItem sx={{ color: "error.main" }}>
					<Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
				</MenuItem>

				<MenuItem>
					<Iconify icon={"eva:cube-outline"} sx={{ mr: 2 }} />
          Services
				</MenuItem>
			</Popover>

			<Backdrop
				sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>

			{alertMessage && (
				<Snackbar
					open={openSnack}
					autoHideDuration={6000}
					onClose={handleCloseSnack}
				>
					<Alert
						onClose={(() => setAlertMessage(null), handleCloseSnack)}
						severity={alertSeverity}
						variant="filled"
						sx={{ width: "100%" }}
					>
						{alertMessage}
					</Alert>
				</Snackbar>
			)}
			<Dialog
				open={openDialog}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Scan who will shop on behalf"}
				</DialogTitle>
				<DialogContent>
					<QrReader
						ViewFinder={function noRefCheck() {}}
						constraints={{
							facingMode: "environment",
						}}
						onResult={(result, error) => {
							if (result) {
								const scannedText = result?.text;

								if (scannedText.includes("pantry")) {
									setData(scannedText);
									setButtonActive(true);
								} else {
									alert("Invalid QR code: does not include 'pantry'");
								}
							}
							if (error) {
								console.info(error);
							}
						}}
						style={{ width: "100%" }}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleSendSMSNonMemberDesignation}
						variant="contained"
						color="info"
					>
						{" "}
            Non member referal
					</Button>
					<Button
						onClick={removeDesignation}
						disabled={
							!selected.some((name) => {
								const user = userList.find((user) => user.name === name);
								return user?.is_shop_on_behalf_active;
							})
						}
						variant="contained"
						color="success"
					>
        Remove designation
					</Button>
					<Button
						onClick={applyDesignation}
						disabled={!buttonActive || !selectedData}
						variant="contained"
						color="success"
					>
    Apply designation
					</Button>
					<Button onClick={handleDialogClose} color="error" variant="outlined">
            Cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
